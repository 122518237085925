/* geist-sans-latin-400-normal */
@font-face {
  font-family: "Geist Sans";
  font-style: normal;
  font-display: auto;
  font-weight: 400;
  src: url(https://cdn.jsdelivr.net/fontsource/fonts/geist-sans@latest/latin-400-normal.woff2)
      format("woff2"),
    url(https://cdn.jsdelivr.net/fontsource/fonts/geist-sans@latest/latin-400-normal.woff)
      format("woff");
}

::-webkit-scrollbar {
  display: none;
}

html,
body {
  overflow-x: scroll;
  width: 100%;
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}

body {
  margin: 0;
  padding: 0;
  font-family: "Geist Sans", sans-serif;
  width: auto;
  color: black;
  overflow: scroll;
}

p {
  font-weight: 400;
}

.small-screen-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100vh;
  text-align: center;
  background-color: black;
  color: #0473bd;
  font-family: Arial, sans-serif;
}

.small-screen-heading {
  font-size: 2rem;
  margin-bottom: 1rem;
}

.small-screen-paragraph {
  font-size: 1.2rem;
}

.login {
  display: flex;
  flex-direction: row;
  margin: 0;
  padding: 0;
  height: 100vh;
  width: 100%;
}

.form-group {
  display: block;
}

.otp-container {
  display: flex;
  flex-direction: row;
  margin: 0;
  padding: 0;
  height: 100vh;
  width: 100%;
}

.input-otp-container {
  display: flex;
  flex-direction: row;
  margin: 0;
  padding: 0;
  height: 100vh;
  width: 100%;
}

.segmented-input {
  display: flex;
  gap: 16.8px;
}

.segment {
  width: 50px;
  height: 54px;
  font-size: 24px;
  text-align: center;
  border: 2px solid #0473bd;
  border-radius: 6px;
  color: #0473bd;
  transition: border-color 0.3s;
}

.segment.shake {
  border-color: red;
  animation: shake 0.3s;
}

.segment.correct {
  border-color: greenyellow;
}

@keyframes shake {
  0%,
  100% {
    transform: translateX(0);
  }
  25% {
    transform: translateX(-5px);
  }
  50% {
    transform: translateX(5px);
  }
  75% {
    transform: translateX(-5px);
  }
}

.segment.empty {
  border-color: #d0d5dd;
}

.segment:focus {
  outline: none;
  border-color: #007bff; /* Change to your desired focus color */
}

.password-reset {
  display: flex;
  flex-direction: row;
  margin: 0;
  padding: 0;
  height: 100vh;
  width: 100%;
}

.flex {
  flex: 1;
}

.login-input {
  width: 100%;
  margin: 0;
  padding: 50px;
  flex: 47.8%;
}

.login-input-main {
  width: 360px;
  height: 390px;
  margin-top: 104px;
}

.otp-input-main {
  width: 360px;
  height: 370px;
  margin-top: 104px;
}

.otp-resend {
  text-align: right;
  margin-top: 16px;
  font-size: 16px;
  color: #737373;
}

.login-input-main p,
.otp-input-main p,
.reset-input-main p,
.input-otp-input-main p {
  color: #737373;
  font-weight: 300;
  font-size: 16px;
  margin-bottom: 32px;
}

.login-input-main h1,
.otp-input-main h1,
.reset-input-main h1,
.input-otp-input-main h1 {
  font-size: 30px;
  font-weight: 600;
}

.login-input-main button {
  width: 100%;
  background-color: #0473bd;
  color: white;
  border-radius: 6px;
  height: 36px;
  border: none;
}

.login-input-main button:active {
  background-color: #0473bd;
  transform: scale(0.98); /* Slightly scales the button down */
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.01),
    /* Smaller shadow */ 0 1px 1px rgba(0, 0, 0, 0.1); /* More subtle shadow */
}

.form-group input[type="email"],
.form-group input[type="password"] {
  width: 100%;
  height: 44px;
  border-radius: 8px;
  border: none;
  border: 1px solid #dde4e9;
  margin-top: 5px;
  padding: 10px;
}

.req input[type="email"],
.req input[type="password"],
.req input[type="text"] {
  width: 100%;
  height: 44px;
  border-radius: 8px;
  border: none;
  border: 1px solid #dde4e9;
  margin-top: 5px;
  padding: 10px;
  margin-bottom: 10px;
}

.login-image1 {
  background: url(/public/images/login-image-new.png);
  background-size: cover;
  background-repeat: no-repeat;
  margin: 0;
  flex: 52.2%;
}

.login-image2 {
  background: url(/public/images/login-image-new.png);
  background-size: cover;
  background-repeat: no-repeat;
  margin: 0;
  flex: 52.2%;
}

.forgot-password {
  text-align: right;
  float: right;
  margin-top: 24px;
  margin-bottom: 24px;
  color: #0473bd;
  font-size: 14px;
  text-decoration: none;
}

.otp-input {
  width: 100%;
  margin: 0;
  padding: 50px;
  flex: 47.8%;
}

.input-otp-input {
  width: 100%;
  margin: 0;
  padding: 50px;
  flex: 47.8%;
}

.otp-button {
  width: 100%;
  background-color: #0473bd;
  color: white;
  border-radius: 6px;
  height: 36px;
  border: none;
  margin-top: 40px;
}

.otp-button:active {
  background-color: #0473bd;
  transform: scale(0.98); /* Slightly scales the button down */
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.01),
    /* Smaller shadow */ 0 1px 1px rgba(0, 0, 0, 0.1); /* More subtle shadow */
}

.reset-input {
  width: 100%;
  margin: 0;
  padding: 50px;
  flex: 47.8%;
}

.reset-button {
  width: 100%;
  background-color: #0473bd;
  color: white;
  border-radius: 6px;
  height: 36px;
  border: none;
  margin-top: 40px;
}

.reset-button:active {
  background-color: #0473bd;
  transform: scale(0.98); /* Slightly scales the button down */
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.01),
    /* Smaller shadow */ 0 1px 1px rgba(0, 0, 0, 0.1); /* More subtle shadow */
}

.reset-input-main {
  width: 360px;
  height: 370px;
  margin-top: 104px;
}

.input-otp-input-main {
  width: 410px;
  height: 370px;
  margin-top: 104px;
}

.dashboard {
  display: flex;
  flex-direction: row;
  margin: 0;
  padding: 0;
  height: auto;
  background-color: black;
  height: 100%;
  width: fit-content;
  min-width: 100vw;
}

.Nav {
  flex: 20%;
  height: 100vh;
}

.sidebar {
  width: 250px;
  color: white;
  padding: 18px;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  height: 100%;
}

.logo {
  align-self: flex-start;
}

.logo img {
  width: 100%;
  max-width: 150px;
  margin-bottom: 20px;
}

.profile {
  display: flex;
  flex-direction: row;
  align-items: center;
  text-align: left;
  margin: 0;
  margin-bottom: 36px;
  gap: 10px;
  width: 100%;
  height: 60px;
  background-color: #262626;
  border-radius: 8px;
  padding-left: 12px;
}

.profile img {
  width: 38px;
  height: 38px;
  margin-bottom: 10px;
  margin-top: 5px;
}

.profile-details .name {
  font-size: clamp(12px, 3vw, 14px);
  font-weight: bold;
  margin: 0;
  margin-bottom: 2px;
  padding-top: 0;
}

.profile-details .role {
  font-size: clamp(10px, 3vw, 12px);
  color: #aaa;
  margin: 0;
  padding-bottom: 2px;
}

.profile-details .id {
  color: #007bff;
  font-size: clamp(10px, 3vw, 12px);
  margin: 0;
}

.nav-menu {
  width: 100%;
}

.nav-item {
  width: 100%;
  padding: 10px 20px;
  text-decoration: none;
  color: #8c8c8c;
  display: flex;
  align-items: center;
  border-radius: 4px;
  transition: background-color 0.3s;
  font-size: 14px;
  margin-bottom: 5px;
}

.nav-item img {
  height: 18px;
  width: 18px;
  margin-right: 8px;
}

.nav-item:hover {
  background-color: #333;
}

.nav-item.active {
  background-color: #333;
  margin-bottom: 5px;
  color: white;
}

.sign-out {
  color: #ff4b4b;
  margin-top: 24px;
  cursor: pointer;
}

.dashboard-display {
  flex: 80%;
}

.dashboard-display {
  width: 100%;
  border-radius: 16px;
  background-color: white;
  margin-top: 12px;
  margin-bottom: 12px;
  margin-right: 12px;
  height: auto;
  padding-bottom: 20px;
  padding: 0;
}

.top-bar {
  display: flex;
  justify-content: space-between; /* Aligns items with space between them */
  align-items: center; /* Align items vertically in the center */
  padding: 10px 20px;
  height: 72px;
  max-height: 72px;
  border-top-right-radius: 16px;
  border-top-left-radius: 16px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.01),
    /* Larger shadow */ 0 1px 3px rgba(0, 0, 0, 0.02); /* Smaller shadow for more depth */
  margin-bottom: 24px;
  width: 100%;
  border-bottom: 1px solid #e1e6ea;
}

.top-bar-info {
  display: inline;
  align-items: center;
  font-size: 14px;
  margin: 0;
  height: 48px;
}

.top-bar-info img {
  width: 18px;
  height: 18px;
  margin-right: 4px;
}

.top-bar-info p {
  font-size: 14px;
  margin: 0;
  font-weight: 600;
}

#date {
  color: #999999;
  font-size: 12px;
}

.top-bar-button {
  display: flex;
  justify-content: space-around;
  flex-direction: row;
  gap: 15px;
}

.top-bar-button button {
  margin-left: auto;
  color: white;
  background-color: #0473bd;
  border-radius: 6px;
  height: 36px;
  width: auto;
  border: none;
  display: flex;
  align-items: center;
  justify-content: space-around;
  font-family: "Geist Sans", sans-serif;
  font-size: 12px;
  gap: 6px;
  padding-left: 12px;
  padding-right: 12px;
}

.top-bar-button button img {
  height: 18px;
  width: 18px;
}

.box {
  text-align: left;
  margin: 10px;
  flex: 1;
  width: 100%;
  height: 79px;
  margin-top: 0;
  padding: 2px;
  border-right: 1px solid #eef1f3;
  padding-right: 20px;
}

.box:last-child {
  border: none;
}

.sub-box {
  border-right: 2px solid #eef1f3;
}

.sub-box:last-child {
  border-right: none;
}

.box-info {
  display: flex;
  justify-content: space-between;
  height: 24px;
}

.message {
  font-size: clamp(0.875em, 3vw, 1em); /* 14px to 16px */
  margin-bottom: 0.625em; /* 10px */
  height: 30px; /* 30px */
  font-weight: bold;
}

.month-comparison {
  color: #999999;
  font-size: clamp(0.75em, 3vw, 0.875em); /* 12px to 14px */
  font-weight: bold;
}

.stat-number {
  font-size: 2em; /* 32px */
  color: #333;
  margin-top: 16px; /* 16px */
}

.stats-row {
  display: flex;
  flex-wrap: wrap; /* Allows boxes to wrap to the next line on smaller screens */
  justify-content: space-around; /* Centers boxes and distributes space between them */
  height: 76px;
  margin-bottom: 32px;
  gap: 75.5px;
  padding: 10px;
}

.main-dashboard {
  display: flex;
  flex-direction: row;
  gap: 24px;
  width: 100%;
  height: 75%;
  padding: 0 20px;
}

.graphs {
  background-color: white;
  flex: 63%;
  max-width: 63%;
  display: flex;
  flex-direction: column;
  margin: 0;
  position: relative;
}

.bar-graph, .line-chart {
  flex: 1; /* Allow both graphs to grow equally */
  width: 100%; /* Ensure they fill the available width */
  max-width: 100%; /* Prevent overflow */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.01), 0 1px 3px rgba(0, 0, 0, 0.1);
  padding: 28px;
  border: 1px solid #eef1f3;
  border-radius: 16px;
  display: flex;
  flex-direction: column;
  margin-bottom: 20px;
}


.bar-chart-container, .line-chart-container {
  height: auto; 
  width: 100%;
  max-width: 100%; 
  flex-grow: 1; 
}

.revenue-header {
  display: flex;
  height: 50px;
  gap: 12px;
  flex: 20%;
}

.attendance-head {
  display: flex;
  height: 50px;
  gap: 12px;
}

.revenue-text p,
.attendance-text2 p,
.attendance-text2 h2,
.revenue-text h2 {
  margin: 0;
  padding: 0;
}

.revenue-text p,
.attendance-text2 p {
  color: #666666;
  font-size: 14px;
}

.revenue-text h2,
.attendance-text2 h2 {
  font-size: 28px;
  font-weight: 600;
}

.revenue-header img,
.attendance-head img {
  height: 50px;
  width: 50px;
}

.daily-attendance {
  flex: 37%;
  width: 37%;
}

.daily-attendance {
  margin-top: 2rem;
  background-color: #fff;
  padding: 0;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.01),
    /* Larger shadow */ 0 1px 3px rgba(0, 0, 0, 0.1); /* Smaller shadow for more depth */
  border-radius: 8px;
  position: relative;
  margin: 0;
  border: 1px solid #eef1f3;
}

.attendance-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 16px;
  padding: 12px 16px;
}

.attendance-header p {
  margin: 0;
  font-weight: 600;
}

.attendance-header button {
  background-color: white;
  color: black;
  border: none;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1), 0 1px 3px rgba(0, 0, 0, 0.08);
  height: 29px;
  border-radius: 6px;
}

.week-dates {
  display: flex;
  justify-content: space-around;
  align-items: center;
  margin: 0;
  background-color: #fcfcfd;
  height: 120px;
}

.date-item {
  background-color: white;
  text-align: center;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.01),
    /* Larger shadow */ 0 1px 3px rgba(0, 0, 0, 0.1); /* Smaller shadow for more depth */
  height: 70px;
  width: 42px;
  border-radius: 12px;
  cursor: pointer;
  margin: 4px; /* Add some margin for spacing */
  display: inline-block; /* Ensure items are inline */
}

.date-item p {
  margin: 0;
  margin-top: 5px;
}

.date-item.active {
  background-color: #2a6ff1;
  color: white;
}

.date-item.active .date-item-days {
  color: white;
}

.date-item-days {
  color: #999999;
}

@media (max-width: 1500px) {
  .stats-row {
    gap: 5px;
  }
}

@media (max-width: 1300px) {
  .message {
    font-size: 15px;
    margin-bottom: 10px;
    height: 30px;
    font-weight: bold;
  }

  .month-comparison {
    color: #999999;
    font-size: 13px;
    font-weight: bold;
  }

  .stat-number {
    font-size: 29px;
    color: #333;
    margin-top: 16px; /* 16px */
  }

  .profile-details .name {
    font-size: 12px;
  }

  .profile-details .role {
    font-size: 10px;
  }

  .profile-details .id {
    color: #007bff;
    font-size: 10px;
  }

  .nav-item {
    font-size: 13px;
  }
}

@media (max-width: 768px) {
  .date-item {
    width: 35px;
    height: 60px;
  }

  .date-item p {
    font-size: 0.9em; /* Smaller font size */
  }
}

@media (max-width: 480px) {
  .date-item {
    width: 30px;
    height: 50px;
  }

  .date-item p {
    font-size: 0.8em; /* Smaller font size */
  }
}

.attendance-status {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 483px;
}

.clock-icon {
  font-size: 3rem;
  color: #007bff;
  margin-bottom: 1rem;
}

.react-calendar {
  border: none !important;
  box-shadow: none;
  margin-bottom: 26px !important;
  width: 280px !important;
}

/* Current date */
.react-calendar__tile--now {
  background-color: #2a6ff1 !important;
  border-radius: 50%;
  color: black;
}

.react-calendar__navigation__label {
  font-family: "Geist Sans", sans-serif;
  font-size: 16px;
  font-weight: bold;
  color: #333;
}

.react-calendar__month-view__weekdays__weekday abbr {
  text-transform: capitalize;
  font-variant: normal;
  text-decoration: none !important;
}

/* Dashboard.css */
.calendar-popup {
  position: absolute;
  top: 50px;
  right: 20px;
  background-color: white;
  border: 1px solid #ccc;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  z-index: 1000;
  padding: 20px;
  border-radius: 8px;
  max-width: 328px;
  max-height: 521px;
}

.calendar-popup .date-item {
  background-color: #007bff;
  color: white;
  border-radius: 50%;
}

.cal-page-btn{
  width: fit-content !important;
}

.calendar-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
}

.calendar-header input {
  width: 128px;
  height: 40px;
  padding: 5px;
  border: 1px solid #ccc;
  border-radius: 8px;
  font-size: 16px;
  font-family: "Geist Sans", sans-serif;
  color: #737373;
  text-align: center;
}

.calendar-header span {
  margin: 0 10px;
}

.calendar-footer {
  display: flex;
  justify-content: space-between;
  margin-top: 10px;
}

.calendar-apply-button {
  border-radius: 8px;
  width: 134px;
  height: 40px;
  border: none;
  color: white;
  background-color: #0473bd;
}

.calendar-cancel-button {
  border-radius: 8px;
  width: 134px;
  height: 40px;
  border: 1px solid #ccc;
  color: black;
  background-color: white;
}

.calendar-footer button:first-child {
  background-color: #f0f0f0;
}

.calendar-footer button:last-child {
  background-color: #007bff;
  color: white;
}

.main-qr {
  height: 90%;
  display: flex;
  justify-content: center;
}

.qr-body {
  padding: 24px;
  border-radius: 8px;
  background-color: #fcfcfd;
  width: 442px;
  height: auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.01),
    /* Larger shadow */ 0 1px 3px rgba(0, 0, 0, 0.1); /* Smaller shadow for more depth */
  margin-bottom: 100px;
}

.spinner {
  border: 4px solid rgba(0, 0, 0, 0.1);
  border-left-color: #0473bd;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  animation: spin 1s linear infinite;
  margin: auto;
}

@keyframes spin {
  to {
    transform: rotate(360deg);
  }
}

.login-spinner {
  border: 4px solid rgba(0, 0, 0, 0.1);
  border-left-color: white;
  border-radius: 50%;
  width: 25px;
  height: 25px;
  animation: spin 1s linear infinite;
  margin: auto;
}

@keyframes spin {
  to {
    transform: rotate(360deg);
  }
}

.qr-body h1 {
  font-size: 24px;
  font-weight: bold;
}

.qr-body button {
  width: 100%;
  color: #0473bd;
  background-color: #fcfcfd;
  height: 44px;
  border-radius: 8px;
  font-weight: bold;
  font-size: 16px;
  border: none;
  border: 1px solid #e1e6ea;
}

.qr-body button:active {
  background-color: #f0f0f0;
  transform: scale(0.98); /* Slightly scales the button down */
  border: 1px solid #e1e6ea;
}

.main-qr img {
  width: 500px;
  max-height: 500px;
  height: auto;
}

.qr-container {
  width: 400px;
  max-height: 400px;
  height: auto;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 32px;
  margin-bottom: 45px;
  background-color: #fcfcfd;
}

.main-settings {
  display: flex;
  flex-direction: row;
}

.settings-nav {
  flex: 23.23%;
  border-radius: 12px;
  max-height: 102px;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 24px;
  margin: 24px;
  margin-top: 0;
  background-color: #fcfcfd;
  border: 1px solid #e8ecef;
  max-width: 219px;
}

.settings-nav button {
  border: none;
  width: 210px;
  height: 36px;
  margin: 8px;
  border-radius: 6px;
  background-color: #fcfcfd;
  color: #8c8c8c;
  font-size: 14px;
  display: flex;
  align-items: center;
  padding-right: 16px;
  padding-left: 16px;
}

.settings-nav button img {
  height: 14px;
  margin-right: 10px;
  width: 14px;
}

.settings-nav button.active {
  border: 1px solid #e8ecef;
  background-color: white;
  color: #0473bd;
  font-weight: bold;
}

.settings-display {
  flex: 76.77%;
  margin: 0 auto;
  padding: 0;
}

.settings-display1 {
  margin: 0;
  padding: 0;
  border: 1px solid #ddd;
  border-radius: 10px;
  background-color: #fff;
  width: auto;
  margin-right: 94px;
}

.settings-display1 h2,
.settings-display2 h2 {
  margin: 0;
  margin-bottom: 20px;
  font-size: 16px;
  font-weight: bold;
  display: flex;
  align-items: center;
  height: 54px;
  padding-left: 16px;
  background-color: #fcfcfd;
  border-radius: 10px;
  border-bottom: 1px solid #ddd;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}

.info-container {
  display: flex;
  flex-direction: row;
  margin-left: 20px;
  margin-right: 20px;
}

.profile-picture {
  flex: 1;
  display: flex;
  flex-direction: row;
  margin-left: 20px;
  margin-right: 20px;
  gap: 16px;
  margin-bottom: 32px;
  align-items: center;
}

.profile-picture p {
  color: #8c8c8c;
  font-size: 12px;
}

.profile-picture img {
  width: 84px;
  height: 84px;
  border-radius: 50%;
  margin-bottom: 10px;
  border: 1px solid #e8ecef;
}

.profile-picture button {
  margin-bottom: 10px;
  border-radius: 6px;
  height: 29px;
  width: 116px;
  border: none;
  border: 1px solid #dae3e9;
  color: #0473bd;
  background-color: white;
  font-size: 14px;
}

.info-fields {
  flex: 3;
}

.field-group {
  display: flex;
  margin-bottom: 20px;
}

.field {
  flex: 1;
  margin-right: 10px;
}

.field label {
  display: block;
  margin-bottom: 5px;
  color: #8c8c8c;
  font-family: "Geist Sans";
  font-size: 14px;
}

.field p {
  margin: 0;
  padding: 10px;
  color: #010101;
  border: 1px solid #eef1f3;
  border-radius: 5px;
  background-color: white;
  font-size: 14px;
  font-family: "Geist Sans";
  font-weight: bold;
  height: 44px;
}

.field-group .field:last-child {
  margin-right: 0;
}

.settings-display2 {
  max-width: 408px;
  max-height: 428px;
  border: 1px solid #ddd;
  border-radius: 10px;
  background-color: #fff;
}

.password-change-form {
  display: flex;
  flex-direction: column;
  margin-left: 24px;
  margin-right: 24px;
}

.password-change-form label {
  display: block;
  margin-bottom: 6px;
  font-size: 14px;
  color: #010101;
}

.settings-form-group {
  margin-bottom: 16px;
  max-width: 360px;
}

.password-input {
  max-width: 360px;
  width: 360px;
  border: 1px solid #e8ecef;
  height: 44px;
  border-radius: 8px;
  padding: 5px;
}

.daily-attendance {
  overflow: hidden;
}

.settings-display2 button {
  margin-top: 32px;
  margin-bottom: 32px;
  width: 360px;
  border-radius: 8px;
  color: white;
  background-color: #0473bd;
  height: 44px;
  border: none;
  font-weight: bold;
  font-size: 16px;
}

.attendance-table {
  width: 100%;
  border: none;
  height: 73%;
  margin-top: 20px;
  position: relative;
  padding-bottom: 70px; /* Space for the pagination controls */
}

.attendance-table-container {
  height: 97.5%;
  overflow-y: scroll;
  padding: 0;
}

.attendance-table ::-webkit-scrollbar {
  display: none; /* Safari and Chrome */
}

.attendance-table table {
  width: 100%;
  overflow: scroll;
  height: auto;
  overflow-y: auto;
}

.attendance-table th,
.attendance-table td {
  padding: 12px;
  border: 1px solid #ddd;
  text-align: left;
  border-left: none;
  border-right: none;
}
.attendance-table td {
  font-size: 14px;
  font-family: "Geist Sans";
}

.attendance-table th {
  background-color: white;
  font-size: 12px;
  font-family: "Geist Sans";
  height: 44px;
  position: sticky;
  top: 0;
}

.attendance-table tbody {
  height: auto;
}

.attendance-table tr:hover {
  background-color: #f1f1f1;
}

.attendance-status {
  text-align: center;
  margin-top: 20px;
}

.attendance-status img {
  width: 100px;
  height: auto;
}

.attendance-status p {
  margin-top: 10px;
  font-size: 16px;
  max-width: 180px;
  color: #101828;
  font-weight: bold;
}

.attendance-table .pagination {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px;
  background: white; /* Adjust as needed to match your design */
  border-top: 1px solid #e4e7ec;
}

.pagination button {
  height: 38px;
  width: 40px;
  margin: 12px;
  border-radius: 6px;
  color: black;
  background-color: white;
  border: 1px solid #d0d5dd;
  cursor: pointer; /* Add cursor pointer to indicate it's clickable */
  transition: background-color 0.3s, color 0.3s; /* Smooth transition for color changes */
}

.pagination button:active {
  transform: scale(0.95); /* Reduce the scale to 95% of the original size */
}

.pagination button.active {
  font-weight: bold;
  background-color: #0473bd;
  color: white;
}

.spinner-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  margin-right: 94px;
  margin-top: 170px;
}

.spinner-container .spinner {
  margin: 0 auto;
  border: 4px solid rgba(0, 0, 0, 0.1);
  width: 36px;
  height: 36px;
  border-radius: 50%;
  border-top-color: #3498db;
  animation: spin 1s ease-in-out infinite;
}

@keyframes spin {
  to {
    transform: rotate(360deg);
  }
}

.add-admin-modal {
  width: 480px;
  height: auto;
  position: fixed;
  top: 50%; /* Center vertically */
  left: 50%; /* Center horizontally */
  transform: translate(-50%, -50%); /* Adjust position based on modal size */
  background-color: white;
  border-radius: 16px;
  border: 1px solid #dde4e9;
  overflow: scroll;
  scrollbar-width: none;
  padding: 0;
}

.add-staff-head {
  height: 53px;
  color: #101828;
  background-color: #fcfcfd;
  display: flex;
  align-items: center;
  padding: 16px;
}

.enter-password {
  height: 53px;
  color: #101828;
  background-color: #fcfcfd;
  display: flex;
  align-items: center;
  padding: 16px;
  border-radius: 16px;
  border: 1px solid #dde4e9;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}

.enter-password h2 {
  font-size: 16px;
  font-weight: bold;
}

.enter-password p {
  margin-top: 8px;
  padding-left: 16px;
  padding-right: 16px;
}

.add-staff-image {
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: auto;
  border-radius: 12px;
  margin: 16px;
  background-color: #fcfcfd;
  border: 1px solid #eef1f3;
  padding: 18px;
  max-height: 75px;
}

.add-staff-image label {
  width: 98px;
  height: 28px;
  border-radius: 8px;
  background-color: white;
  border: 1px solid #eef1f3;
  color: #0473bd;
  font-size: 12px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.add-staff-image-pic {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 14px;
}

.add-staff-image-text {
  display: flex;
  flex-direction: column;
  font-size: 12px;
}

.add-staff-image-text p {
  margin: 2px;
}

.add-admin-modal h2 {
  font-size: 16px;
  font-weight: bold;
}

.modal-heading {
  height: 48px;
  background-color: #fcfcfd;
  border-radius: 16px;
  border: 1px solid #eef1f3;
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
  padding: 10px;
}

.modal-heading h2 {
  font-size: 16px;
  font-weight: 700;
  margin-bottom: 16px;
}

.add-staff-id {
  height: 76px;
  background-color: #fcfcfd;
  margin: 16px;
  border-radius: 12px;
}

.add-admin-modal .inner-form {
  padding: 16px;
}

.add-admin-modal input,
.add-admin-modal select {
  height: 40px;
  border: 1px solid #e1e6ea;
  margin-bottom: 10px;
  margin-top: 8px;
  border-radius: 8px;
  padding: 5px;
  color: black;
  font-size: 14px;
  width: 438px;
}

.add-admin-modal form h2 {
  font-size: 14px;
  font-weight: 600;
}

.add-admin-modal form label {
  font-size: 12px;
  color: #737373;
  margin: 0;
}

.edit-success-modal {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 32px;
  padding-left: 40px;
  padding-right: 40px;
  justify-content: center;
  width: 440px;
  height: auto;
  position: fixed;
  top: 50%; /* Center vertically */
  left: 50%; /* Center horizontally */
  transform: translate(-50%, -50%); /* Adjust position based on modal size */
  background-color: white;
  border-radius: 16px;
  border: 1px solid #dde4e9;
}

.delete-modal {
  width: 440px;
  height: auto;
  position: fixed;
  top: 50%; /* Center vertically */
  left: 50%; /* Center horizontally */
  transform: translate(-50%, -50%); /* Adjust position based on modal size */
  background-color: white;
  border-radius: 16px;
  border: 1px solid #dde4e9;
  padding: 0;
}

.delete-modal-inner {
  margin: 0;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.delete-modal-button {
  display: flex;
  flex-direction: column;
  gap: 5px;
  width: 100%;
  max-width: 360px;
  margin-bottom: 32px;
}

.delete-modal-button button {
  color: black;
  background-color: white;
  height: 44px;
  border-radius: 8px;
  border: none;
}

.delete-modal-button button:first-child {
  background-color: #f03a47;
  color: white;
}

.delete-modal-inner p {
  max-width: 360px;
  text-align: center;
  font-weight: bold;
}

.edit-success-modal img {
  width: 66px;
  height: 66px;
  margin-bottom: 24px;
}

.delete-modal img {
  margin-top: 32px;
  width: 66px;
  height: 66px;
  margin-bottom: 24px;
}

.edit-success-modal button {
  border-radius: 8px;
  background-color: #0473bd;
  color: white;
  width: 100%;
  height: 44px;
  border: none;
  margin-top: 24px;
}

.edit-success-modal h2 {
  font-size: 24px;
  margin-bottom: 8px;
}

.edit-success-modal p {
  color: #667085;
  font-family: "Geist Sans";
  font-size: 16px;
}

.view-staff-modal {
  position: fixed;
  top: 50%; /* Center vertically */
  left: 50%; /* Center horizontally */
  transform: translate(-50%, -50%); /* Adjust position based on modal size */
  background-color: white;
  border-radius: 16px;
  border: 1px solid #dde4e9;
  width: 480px;
  height: auto;
}

.view-staff-form label {
  font-size: 12px;
  padding-left: 16px;
  font-weight: bold;
  margin-bottom: 8px;
}

.view-staff-form input[type="password"],
.view-staff-form input[type="number"] {
  margin-left: 16px;
  margin-right: 160px;
  border: 1px solid #e1e6ea;
  height: 40px;
  border-radius: 8px;
  margin-bottom: 24px;
  width: 100%;
  max-width: 448px;
  padding-left: 5px;
}

.view-staff-form button[type="submit"] {
  height: 44px;
  color: white;
  background-color: #0473bd;
}

.view-staff-form button[type="button"] {
  height: 44px;
}

.view-staff-modal p {
  margin: 16px;
  margin-top: 8px;
  margin-bottom: 24px;
  font-size: 14px;
  color: #737373;
}

.modal-button {
  display: flex;
  flex-direction: row;
  margin: 0;
  margin-top: 10px;
  padding: 16px;
  gap: 16px;
  font-size: 14px;
  background-color: #fcfcfd;
  border: 1px solid #dfe3ea;
  height: auto;
}

.modal-button button {
  flex: 50%;
  height: 40px;
  margin: 0;
  border: 1px solid #dfe3ea;
  border-radius: 8px;
  background-color: white;
  color: black;
  font-weight: 600;
}

.modal-button input[type="submit"] {
  flex: 50%;
  height: 40px;
  margin: 0;
  background-color: #0473bd;
  color: white;
  font-weight: 600;
}

.add-admin-modal2 {
  width: 440px;
  height: auto;
  position: fixed;
  top: 50%; /* Center vertically */
  left: 50%; /* Center horizontally */
  transform: translate(-50%, -50%); /* Adjust position based on modal size */
  background-color: #ffffff;
  padding: 32px;
  border-radius: 16px;
  border: 1px solid #dde4e9;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.add-admin-modal2 p {
  color: #667085;
  font-size: 16px;
}

.add-admin-modal2 h2 {
  color: black;
  font-size: 24px;
  font-weight: 700;
  margin-bottom: 8px;
}

.add-admin-modal2 img {
  width: 66px;
  height: 66px;
  margin-bottom: 24px;
}

.add-admin-modal2 button {
  margin-top: 5px;
  max-width: 360px;
  width: 100%;
  height: 44px;
  border-radius: 10px;
  background-color: #0473bd;
  color: white;
  font-size: 16px;
  font-weight: 500;
  border: none;
}

.details-container {
  height: auto;
  background-color: #fcfcfd;
  width: auto;
  border: 1px solid #eef1f3;
  border-radius: 8px;
  padding: 10px;
  margin-top: 24px;
  margin-bottom: 24px;
  margin-left: 16px;
  margin-right: 16px;
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.detail {
  display: flex;
  justify-content: space-between;
  font-size: 14px;
  color: #737373;
  gap: 50px;
  height: 28px;
}

.detail img {
  width: 16px;
  height: 16px;
}

.detail span {
  display: flex;
  flex-direction: row;
  gap: 6px;
}

.D {
  color: black;
  text-align: right;
}

.show-admin-table,
.member-sub-table {
  height: auto;
  margin: 24px;
  border-radius: 8px;
  border: 1px solid #eef1f3;
}

.admin-table {
  width: 100%;
  height: auto;
  border-radius: 30px !important;
}

.admin-table th tr {
  border-radius: 8px !important;
}

.admin-table th {
  background-color: #fcfcfd;
  border-radius: 8px !important;
  padding-right: 24px;
  padding-left: 24px;
  color: black;
  height: 44px;
  font-size: 12px;
}

.admin-table tr {
  height: 72px;
  border: 1px solid #ecedf4;
}

.admin-table td {
  padding-right: 24px;
  padding-left: 24px;
  font-size: 14px;
  color: #737373;
  font-weight: 500;
}

.options-menu {
  position: relative;
  display: inline-block;
}

.options-menu img {
  width: 50px;
  height: 50px;
  cursor: pointer;
}

.options-menu .popup {
  position: absolute;
  top: 100%;
  left: -150px;
  z-index: 10;
  background-color: #fff;
  border: 1px solid #ccc;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
  border-radius: 4px;
  width: 120px;
  width: 211px;
  font-family: "Geist Sans";
  font-size: 14px;
  color: #010101;
  border-radius: 8px;
}

.options-menu .popup ul {
  list-style: none;
  padding: 0;
  margin: 0;
  border-radius: 8px;
}

.options-menu .popup ul li {
  padding: 10px;
  cursor: pointer;
  border: 1px solid #eef1f3;
  font-weight: 500;
}

.options-menu .popup ul li:hover {
  background-color: #f5f5f5;
}

/* Add any additional styles here */

.options-menu img {
  height: 16px;
  width: 16px;
  margin-right: 8px;
  margin-left: 8px;
}

.staff-pagination {
  display: flex;
  justify-content: space-between;
  padding: 15px;
  align-items: center;
  color: black;
  font-weight: 700;
  height: 66px;
  border-top: 1px solid #d0d5dd;
  font-family: "Geist Sans";
  font-size: 14px;
}

.staff-pagination button {
  width: 30px;
  height: 30px;
  color: black;
  font-weight: 700;
  font-family: "Geist Sans";
  margin: 6px;
}

.staff-items {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 24px;
  padding-top: 0;
  padding-bottom: 0;
  margin: 0;
  font-size: 14px;
}

.search input[type="text"] {
  width: 251px;
  height: 36px;
  background-color: white;
  border: 1px solid #e1e6ea;
  border-radius: 8px;
  padding: 10px;
  color: #737373;
  font-size: 14px;
}

.export {
  display: flex;
  align-items: center;
  gap: 12px;
}

.export-group {
  display: flex;
  align-items: center;
  gap: 12px;
}

.export button {
  width: auto;
  height: 36px;
  border-radius: 8px;
  border: none;
  border: 1px solid #e1e6ea;
  background-color: white;
  color: #666666;
  display: flex;
  align-items: center;
  gap: 7px;
  padding-left: 10px;
  padding-right: 10px;
}

.export img {
  width: 16px;
  height: 16px;
}

.view-info-modal {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 440px;
  height: auto;
  position: fixed;
  top: 50%; /* Center vertically */
  left: 50%; /* Center horizontally */
  transform: translate(-50%, -50%); /* Adjust position based on modal size */
  background-color: white;
  border-radius: 16px;
  border: 1px solid #dde4e9;
}

.view-info-inner {
  width: 100%;
  margin: 0;
  border-radius: 16px;
}

.view-info-button {
  margin: 16px;
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.view-info-button button {
  color: #0473bd;
  background-color: white;
  height: 44px;
  border-radius: 8px;
  font-size: 16px;
  font-weight: bold;
  border: none;
}

.view-info-button button:first-child {
  background-color: #0473bd;
  color: white;
}

.view-info-head {
  border-radius: 16px;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}

.view-info-head h2 {
  font-size: 16px;
  font-weight: bold;
}

.view-staff-name {
  display: flex;
  align-items: center;
  gap: 10px;
  margin-top: 18px;
  margin-bottom: 18px;
  margin-left: 16px;
}

.view-staff-name p {
  margin-top: 15px;
}

.view-staff-name img {
  height: 48px;
  width: 48px;
}

.sub-dashboard {
  display: flex;
  flex-direction: column;
  height: auto;
}

.subscription-graph {
  height: 332px;
  margin-top: 24px;
  margin-bottom: 24px;
  padding: 40px;
  padding-top: 60px;
  padding-bottom: 10px;
  border: 1px solid #eef1f3;
  border-radius: 16px;
  font-family: "Geist Sans";
  position: relative; /* Necessary for absolute positioning */
  display: flex;
  align-items: center;
  justify-content: center;
  box-sizing: border-box; /* Ensure padding is considered in height calculation */
}

.sub-items {
  padding: 0;
  margin-bottom: 16px;
  margin-top: 16px;
}

.recent-subs h2 {
  font-size: 18px;
}

.sub-plan-table,
.sub-log h2 {
  margin-top: 30px;
}

.sub-plan-table h2,
.sub-log h2 {
  font-size: 18px;
  margin-bottom: 15px;
}

.subscriptionChart {
  width: auto;
  height: 400px;
}

.sub-confirm img {
  width: 80px;
  height: 80px;
  display: block;
  margin: 0 auto;
  margin-top: 24px;
  margin-bottom: 24px;
}

@media (max-height: 901px) {
  .notif-dashboard {
    height: 100vh;
  }

  .notification-container {
    max-height: 900px;
    min-height: 700px;
  }

  .notif-container-display {
    height: 97%;
    overflow: scroll;
  }
}

.notif-dashboard {
  height: 100vh;
}

.notification-container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  gap: 32px;
  height: 80%;
  flex-wrap: wrap; /* Allows wrapping */
}

.show-notifications,
.send-notifications {
  flex: 1 1 48%; /* Allows the items to shrink and grow */
  height: 100%; /* Adjust height to fit content */
  border-radius: 16px;
  padding: 0;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.01), 0 1px 3px rgba(0, 0, 0, 0.1);
  box-sizing: border-box;
  min-width: 300px; /* Minimum width to prevent too much shrinking */
}

.send-notifications {
  background-color: #fcfcfd;
  overflow-y: scroll;
}

.nil-notif {
  color: #0473bd;
  font-weight: bold;
  text-align: center;
  margin-top: 50px;
}

.notif-head {
  padding: 16px;
  color: black;
  border-bottom: 1px solid #e1e6ea;
}

.notif-head h2 {
  font-size: 16px;
  font-weight: bolder;
}

.notif-search {
  display: flex;
  justify-content: space-between;
  gap: 8px;
  font-size: 14px;
  margin: 16px;
}

.search-input {
  flex: 80%;
  height: 40px;
  border-radius: 8px;
  color: #737373;
  border: 1px solid #e1e6ea;
  padding-left: 10px;
}

.search-btn {
  flex: 20%;
  height: 40px;
  border-radius: 8px;
  background-color: #0473bd;
  color: white;
  border: none;
}

.notif-body {
  height: 75%;
  overflow-y: auto; /* Allows vertical scrolling */
  border-radius: 8px;
  border-top: 1px solid #e1e6ea;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.notif {
  font-size: 14px;
  display: flex;
  gap: 10px;
  padding: 16px;
  border-bottom: 1px solid #e1e6ea;
}

.initials-container {
  padding: 0;
  width: fit-content;
}

.notif-initials {
  margin: 0;
}

.notif-text {
  display: flex;
  flex-direction: column;
  gap: 0;
  flex: 60%;
}

.notif-text p {
  margin-bottom: 1px;
}

.notif-time {
  flex: 5%;
  height: fit-content;
}

.notif-footer {
  border-top: 1px solid #e1e6ea;
  height: 8%;
}

.notif-btn {
  margin-top: 16px;
  display: flex;
  gap: 10px;
}

.notif-btn button {
  border: 1px solid #e1e6ea;
  background-color: white;
  height: 36px;
  width: auto;
  border-radius: 8px;
  font-size: 14px;
  font-weight: 600;
  padding: 6px;
}

.approve img,
.decline img {
  width: 80px;
  height: 80px;
  display: block;
  margin: 0 auto;
  margin-top: 24px;
  margin-bottom: 24px;
}

.send-notifications {
  display: flex;
  flex-direction: column;
  padding: 16px;
  overflow: hidden;
}

.send-notifications h3 {
  flex: 5%;
  font-size: 16px;
  font-weight: bolder;
}

.send-notifications p {
  flex: 5%;
  font-size: 14px;
  color: #737373;
}

.send-notif-form {
  flex: 90%;
  position: relative;
}

.notif-button {
  flex: 2; /* 20% of the height */
  display: flex;
  justify-content: center; /* Center button horizontally */
  align-items: center; /* Center button vertically */
  position: absolute;
  bottom: 0;
  width: 100%;
}
.notif-button button {
  width: 100%;
  height: 44px;
  font-size: 16px;
  border-radius: 8px;
  font-weight: bold;
  border: none;
  background-color: #0473bd;
  color: white;
  margin-top: 20px;
}
.notif-message {
  margin-top: 20px;
  margin-bottom: 20px;
}

.notif-message-image {
  height: 170px;
}

.image-input-container img {
  height: 40px;
  width: 40px;
  margin-bottom: 12px;
}

.image-input-container {
  border: 1px solid #e7ecf3;
  height: 143px;
  border-radius: 16px;
  background-color: white;
  display: flex;
  align-items: center;
  flex-direction: column;
  padding: 24px;
}

.notif-header label,
.notif-message label,
.notif-message-image h1 {
  display: block;
  margin-bottom: 8px;
  font-size: 12px;
  color: black;
  font-weight: bold;
}

.image-upload-success {
  height: 72px;
  border: 1px solid #e7ecf3;
  background-color: #f9fafb;
  border-radius: 8px;
  padding: 16px;
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 10px;
}

.image-upload-success img {
  width: 28px;
  height: 28px;
}

.upload-success-details h1,
.upload-success-details p {
  margin: 0;
  font-size: 14px;
}

.preview-body {
  padding: 0 16px;
  margin-top: 16px;
}

.preview-body h3 {
  font-size: 18px;
  margin-bottom: 16px;
}

.preview-body p {
  font-size: 14px;
  color: 55eE5E;
  margin: 0;
}

.preview-image-container img {
  width: 113px;
  height: 126px;
  border-radius: 12px;
  margin-top: 28px;
  margin-bottom: 27px;
}

.notif-header {
  margin-bottom: 16px;
}

.notif-header input {
  display: block;
  margin-bottom: 8px;
  width: 100%;
  height: 40px;
  border-radius: 8px;
  border: none;
  border: 1px solid #e1e6ea;
  padding: 5px;
}

.notif-message textarea {
  width: 100%;
  height: calc(100% - 8px); /* Take 90% of the container's height */
  border-radius: 8px;
  border: none;
  border: 1px solid #e1e6ea;
  padding: 5px;
  min-height: 220px;
}

/* Media queries for responsive design */
@media (max-width: 768px) {
  .notification-container {
    flex-direction: column;
  }

  .show-notifications,
  .send-notifications {
    flex: 1 1 100%;
    max-width: 100%;
  }

  .notif-body {
    height: auto;
  }
}

.form-step-indicator {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 12px;
}

.form-step-indicator p {
  font-weight: bold;
  font-size: 14px;
  color: #737373;
  margin-top: 14px;
}

.form-step-indicator .step-indicator-container {
  display: flex;
  align-items: center;
  flex: 1;
}

.form-step-indicator .step {
  width: 24px; /* Adjust size if needed */
  height: 24px; /* Adjust size if needed */
  border-radius: 50%;
  background-color: #ccc; /* Ash color for inactive state */
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 16px; /* Adjust font size if needed */
  position: relative;
}

.form-step-indicator .step.inactive {
  background-color: #ccc; /* Ash color for inactive state */
}

.form-step-indicator .step.inactive::before {
  content: "";
  width: 8px; /* Adjust size of the inner circle if needed */
  height: 8px; /* Adjust size of the inner circle if needed */
  border-radius: 50%;
  background-color: white;
  position: absolute;
}

.form-step-indicator .step.active {
  background-color: #0473bd; /* Blue color for active state */
}

.form-step-indicator .step.active::before {
  content: "";
  width: 8px; /* Adjust size of the inner circle if needed */
  height: 8px; /* Adjust size of the inner circle if needed */
  border-radius: 50%;
  background-color: white;
  position: absolute;
}

.form-step-indicator .step.completed {
  background-color: #0473bd; /* Blue color for completed state */
}

.form-step-indicator .step.completed::after {
  content: "\2713"; /* Unicode checkmark character */
  color: white;
  font-size: 16px; /* Adjust font size if needed */
  position: absolute;
}

.form-step-indicator .divider {
  flex: 1;
  height: 2px;
  background-color: #ccc;
  margin: 0 10px;
}

.member-name {
  display: flex;
  flex-direction: row;
  gap: 16px;
}

#member-fname {
  flex: 50%;
}

#member-lname {
  flex: 50%;
}

#member-lname input,
#member-lname label {
  width: 100%;
}

#member-fname input {
  width: 100%;
}

.form-container .inner-form {
  padding: 0;
}

.form-container .inner-form label {
  font-size: 12px;
  color: #737373;
}

.form-container .inner-form .add-staff-head {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.form-container .inner-form .modal-button button:last-child {
  color: white;
  background-color: #0473bd;
}

.form-input {
  padding: 16px;
}

.inner-member {
  text-align: center;
}

.warning {
  height: auto;
  background-color: #fff6e5;
  color: #b0781c;
  font-size: 14px;
  display: flex;
  padding: 10px;
  flex-direction: row;
  align-items: center;
  gap: 10px;
  margin: 24px;
  border-radius: 8px;
}

.warning p {
  margin: 0;
  text-align: left;
}

.warning img {
  width: 17px;
  height: 17px;
}

.inner-member-btn {
  display: flex;
  padding: 24px;
  padding-top: 0;
  gap: 16px;
}

.inner-member-btn button {
  height: 44px;
  border: 1px solid #dfe3ea;
  border-radius: 8px;
  background-color: white;
  color: black;
  font-size: 16px;
  font-weight: bold;
}

.inner-member-btn button:first-child {
  flex: 40%;
}
.inner-member-btn button:last-child {
  flex: 60%;
}

.member-preview-head {
  margin: 0 24px;
  display: flex;
  gap: 8px;
  align-items: center;
  margin-top: 24px;
  margin-bottom: 10px;
}

.member-preview-head p {
  margin: 0;
  font-weight: bold;
  font-size: 16px;
}

.user-profile-display {
  display: flex;
  flex-direction: row;
  gap: 20px;
}

.member-details {
  flex: 40%;
  width: 40%;
  border: 1px solid #eef1f3;
  margin-left: 24px;
  border-radius: 18px;
}

.gym-information {
  flex: 60%;
  width: 60%;
  border: 1px solid #e1e6ea;
  border-radius: 18px;
  margin-right: 24px;
}

.member-sub-table {
  margin: 24px;
  margin-top: 0;
}

.user-profile-btn button {
  background-color: white;
  color: black;
  border: 1px solid #e1e6ea;
}

.add-member-head {
  display: flex;
  justify-content: space-between;
  padding: 15px;
}

.add-member-head h2 {
  font-size: 16px !important;
  font-weight: bold;
}

.add-member-head button {
  height: 29px;
  border: 1px solid #dae3e9;
  font-size: 14px;
  color: #006db0;
  border-radius: 6px;
  background-color: white;
  font-weight: normal;
}

.user-stat {
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.user-stat-r1 {
  height: auto;
  border: 1px solid #eef1f3;
  flex: 50%;
  display: flex;
  flex-direction: row;
  margin: 0 16px;
  background-color: #fcfcfd;
  border-radius: 8px;
  margin-top: 0;
  padding: 10px;
}

.user-stat-r1 > * {
  flex: 1;
  height: 80px;
}

.user-stat-r2 {
  height: auto;
  border: 1px solid #eef1f3;
  flex: 50%;
  display: flex;
  flex-direction: row;
  margin: 0 16px;
  background-color: #fcfcfd;
  border-radius: 8px;
  padding: 10px;
}

.user-stat-r2 > * {
  flex: 1;
  height: 80px;
}

.gym-information .add-member-head,
.member-details .add-member-head {
  background-color: #fcfcfd;
  margin-bottom: 9px;
  border-bottom: 1px solid #eef1f3;
}

#gym-stat-head {
  margin-left: 16px;
  font-size: 14px;
  color: #8c8c8c;
  font-weight: bold;
  margin-top: 8px;
  margin-bottom: 4px;
}

.stat-box p {
  font-size: 14px;
  color: #737373;
}

.stat-box h3 {
  font-size: 14px !important;
  font-weight: bold;
}

.stat-box img {
  height: 18px;
  width: 18px;
}

.stat-box span {
  display: flex;
  flex-direction: row;
  gap: 6px;
}

#history-head {
  font-size: 16px;
  font-weight: bold;
  margin-left: 24px;
  margin-top: 24px;
  margin-bottom: 0;
  margin-bottom: 16px;
}

.member-calendar {
  height: 315px;
  background-color: #fcfcfd;
  margin-top: 10px;
  z-index: 0;
}

.member-calendar .react-calendar {
  width: 100% !important;
  height: 100% !important;
  object-fit: contain !important;
  background-color: #fcfcfd;
  margin-top: 10px;
}

.member-calendar .react-calendar__navigation button:first-child {
  display: none;
}

.member-calendar .react-calendar__navigation button:last-child {
  display: none;
}

.member-calendar .react-calendar__navigation__label {
  font-family: "Geist Sans", sans-serif;
  font-size: 16px;
  font-weight: bold;
  color: #333;
}

.member-calendar .react-calendar__tile--active {
  background: none;
}

.member-calendar .react-calendar__tile {
  pointer-events: none;
}

.member-calendar .react-calendar__tile--now {
  background: #6f48eb33;
  border-radius: 50%;
  height: 100%;
  font-weight: bold;
  color: white;
}

.member-calendar .react-calendar__tile--now {
  position: relative;
  background-color: transparent !important;
}

.member-calendar .react-calendar__tile--now::before {
  content: "";
  width: 40px; /* Fixed width */
  height: 100%; /* Fixed height */
  background-color: #2a6ff1; /* Custom color */
  border-radius: 50%; /* Make it circular */
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 1;
}

.member-calendar .react-calendar__tile--now abbr {
  position: relative;
  z-index: 1; /* Place the date text above the indicator */
  color: white; /* Ensure contrast against the indicator */
}

.attendance-text {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: auto;
  height: auto;
  margin: 0 auto;
  margin-top: 10px;
  gap: 5px;
}

.attendance-indicator {
  width: 8px;
  height: 8px;
  background-color: #e0f4fe;
  border-radius: 50%;
}

.attendance-indicator p {
  display: none;
}

.attendance-text p {
  font-size: 14px;
  color: #010101;
  margin: 0;
}

.attendance-description-outer {
  padding: 0 23px;
}

.attendance-description-outer p {
  font-size: 14px;
  font-weight: bold;
}

.attendance-description {
  width: 273.5px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 8px 16px;
  border: 1px solid #eef1f3;
  border-radius: 8px;
  margin-top: 0;
}

.attendance-description p {
  margin: 0;
}

.description-inner {
  display: flex;
  flex-direction: row;
  align-items: center;
  width: fit-content;
  gap: 5px;
}

.description-inner p {
  margin: 0;
  font-weight: normal;
}

.custom-overlay {
  background-color: red !important;
}

.ReactModal__Overlay {
  background-color: rgba(
    178,
    178,
    178,
    0.18
  ) !important; /* Light gray with 18% opacity */
  backdrop-filter: blur(2px) !important; /* Blur effect */
}

.address-container {
  position: relative;
  max-width: 300px; /* Set the max width */
  overflow: hidden;
}

.address-text {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 100%; /* Ensure it respects the container's width */
  cursor: pointer;
  color: #010101;
}

.ReactModal__Content {
}

.form-input {
  max-height: 400px;
  overflow: auto;
}

.staff-form {
  padding: 0;
  margin: 0;
}

.staff-form .inner-form {
  max-height: 400px;
  overflow-y: auto;
  overflow-x: hidden;
}

/* Prevents background scrolling when the modal is open */
.no-scroll {
  overflow: hidden;
}

.modal-content {
  display: flex;
  align-items: center;
  justify-content: center;
}

.modal-content h2 {
  margin-bottom: 24px;
  font-size: 24px;
  font-weight: bolder;
}

.qr-notif-box {
  display: flex;
  flex-direction: row;
  gap: 16px;
  background-color: #fcfcfd;
  color: black;
  max-height: 80px;
  padding: 8px 12px;
  border-radius: 12px;
  width: 100%;
  border: 1px solid #eef1f3;
}

.notif-details p {
  font-weight: bold;
}

.notif-span {
  margin-right: 10px;
  color: #636363;
  font-weight: normal;
  font-size: 14px;
}

.qr-notif-box img {
  border: 1px solid #eef1f3;
  border-radius: 50%;
  margin: 0;
  padding: 0;
}

.qr-notif-details p {
  color: black;
  font-weight: bold;
  font-size: 14px;
}

.clock-in-btns {
  width: 100%;
  margin: 0;
  padding: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 20px;
  background-color: #f5f7fa;
}

.clock-in-btns button {
  margin: 0;
  padding: 0;
  height: 40px;
  width: 180px;
  margin: 16px 0;
  font-size: 14px;
  border-radius: 8px;
}

.clock-in-btns button:first-child {
  background-color: white;
  border: 1px solid #dfe3ea;
  color: black;
  font-weight: bold;
}

.clock-in-failure {
  padding-left: 0;
  padding-right: 0;
  padding-bottom: 0;
}

.clock-in-failure p {
  text-align: center;
  margin: 0 24px;
  margin-bottom: 32px;
}
